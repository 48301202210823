import 'jquery-ui';
import {AjaxPromise} from "../shared/ajax_utilities";
import {displayErrors} from "../shared/common";

$(() => {

    app.DOM.user_id = app.DOM.form.find('#user_id');

    // button link
    app.DOM.form.find('.btn-link').on('click', async (e) => {
        const user_id = $(e.currentTarget).attr('data');
        const userName = $(e.currentTarget).attr('data-name');

        if( confirm(`Are you sure you want to link:\n\nDriver: ${app.OPTIONS.driver.driver_name}\nto\nUser: ${userName}`) ) {
            await ajax(user_id);
        }
    });

    // autocomplete change
    app.DOM.user_id.on('change', function(){

        const user_id = this.value;

        setTimeout(async () => {

            let userName = app.DOM.user_name.val().split('-')[0];

            if( user_id && confirm(`Are you sure you want to link:\n\nDriver: ${app.OPTIONS.driver.driver_name}\nto\nUser: ${userName}`) ) {
                await ajax(user_id);
                return;
            }

            app.DOM.user_name.val('');
            app.DOM.user_id.val('');
        }, 300);

    });

    async function ajax(user_id)
    {
        const redirectURL = `${app.CACHE.URL_ADMIN}tco_drv/${app.URI[2]}/tab/calendar`;
        const url = `${app.CACHE.URL_AJAX}tacho_link_driver/assign/${app.URI[2]}/${user_id}`;

        try {
            const res = await AjaxPromise({
                'method': 'POST',
                'url': url
            });

            if(res.status === 'success') {
                window.location = redirectURL;
                return
            }

            displayErrors(res);
        } catch(err) {
            displayErrors(err);
        }
    }
});